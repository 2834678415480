// Referências externas
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiPrinter, FiAlertTriangle } from 'react-icons/fi';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Referências internas
import { Header, Loading, DynamicLoading, ProposalPrint, BotaoSalvarComo } from '../../common'
import api from '../../services/api';
import Form from './Componentes/Formulario/Form';
import FormLoading from './Componentes/Formulario/FormLoading';
import userType from '../../services/authorization';
import ModalVoltar from './Componentes/Formulario/ModalVoltar';
import Resumo from './Componentes/Resumo';
import RetornoFinanceiro from './Componentes/RetornoFinanceiro';
import ModalSalvarComo from '../../common/BotaoSalvarComo/ModalSalvarComo';
// import Modal1 from '../../common/Modal1/Modal1';

// Estilo
import './styles.css';
import './tabs-styles.css';

// Referências internas
const permissaoUsuario = require('../../services/permissaoUsuario');

const PropostasResumidasSimulacao = props => {
    const history = useHistory();

    const [token, setToken] = useState({});

    // const [modal1Aberto, setModal1Aberto] = useState(false);
    const [modalSalvarComoAberto, setModalSalvarComoAberto] = useState(false);
    const [modalVoltarAberto, setModalVoltarAberto] = useState(false);

    // const [dadosEnvioProposta, setDadosEnvioProposta] = useState(0);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [resumo, setResumo] = useState({
        sistema_necessario: 0,
        sistema_sugerido: 0,
        conta_sem_sistema: 0,
        conta_com_sistema: 0,
        opex_fiob_icms_mensal_medio_ano1: 0,
        economia: 0,
        geracao_media: 0,
        valor_final: 0,
        valor_final_kwp: 0,
        valor_comissao: 0,
        etapa1: 0,
        etapa2: 0,
        etapa3: 0,
        payback_string: '',
        parcial_produto: 0,
        parcial_produto_kwp: 0,
        parcial_servico: 0,
        parcial_servico_kwp: 0,
        degradacao_ano1: 0,
        degradacao_subsequente: 0,
        icms_tarifa: 0,
        mao_obra_transporte: 0,
        dias_obra: 0,
    });

    const [analise, setAnalise] = useState({
        vpl_escalonado: [],
        tir_escalonado: [],
        economia_escalonado: []
    })
    
    const [done, setDone] = useState(false);
    const [tabelaDone, setTabelaDone] = useState(false);

    const [novaProposta, setNovaProposta] = useState(true);

    // Variável para controlar se alguma informação inserida pelo usuário ainda não foi salva.
    // O propósito é controlar a saída desta página. Caso seja 'true' irá exibir o modal de
    // confirmação após o botão 'Voltar' ser pressionado. 
    const [propostaAlterada, setPropostaAlterada] = useState(false);
    const [resumoAnaliseInvalido, setResumoAnaliseInvalido] = useState(true);

    const [salvarBloqueado, setSalvarBloqueado] = useState(true);
    const [salvarCarregando, setSalvarCarregando] = useState(false);

    const [impressaoBloqueada, setImpressaoBloqueada] = useState(true);
    const [impressaoCarregando, setImpressaoCarregando] = useState(false);
    const [dadosImpressao, setDadosImpressao] = useState({});

    // Cabeçalho e controle da proposta exibida
    const [actionTitle, setActionTitle] = useState('PROPOSTA');
    const [tabelaConsiderada, setTabelaConsiderada] = useState('');
    const [tabelaVigente, setTabelaVigente] = useState('');
    const [clientID, setClientID] = useState('');
    const [vocativo, setVocativo] = useState('');
    const [numero_proposta, setNumero_proposta] = useState(0);
    const [revisao_proposta, setRevisao_proposta] = useState(0);
    const [data_proposta_string, setData_proposta_string] = useState(0);

    // Dados enviados para <Form />
    const [dadosRoute, setDadosRoute] = useState('');
    const [propostaExistente, setPropostaExistente] = useState({});
    const [estadoFormulario, setEstadoFormulario] = useState({
        concessionaria: '',
        responsavel: 'vazio',
        id_responsavel: 0,
        comissao: 0.00,
        percentual_atendido: 100,
        tarifa: 0.00,
        valor_fixo: 0,
        taxa_disponibilidade: 100,
        injecao: 0,
        icms_tarifa: 0,
        parcela_tusd: 0,
        parcela_fiob: 0,
        aplicacao_estrutura: '',
        tipo_estrutura: '',
        modulo_modelo: '',
        modulo_potencia: 0,
        modulo_area: 0,
        modulo_peso: 0,
        modulo_garantia1: 0,
        modulo_garantia2: 0,
        modulo_campo_adicional1: '',
        desconto_weg_proposta: [0, 0, 0, 0],
        consumo_medio: 0,
        curva_consumo: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        consumo_janeiro: 0,
        consumo_fevereiro: 0,
        consumo_marco: 0,
        consumo_abril: 0,
        consumo_maio: 0,
        consumo_junho: 0,
        consumo_julho: 0,
        consumo_agosto: 0,
        consumo_setembro: 0,
        consumo_outubro: 0,
        consumo_novembro: 0,
        consumo_dezembro: 0,
        relacao_geracao: 0,
        curva_geracao: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        tipo_inversor: '',
        status_proposta: ''

    });

    // Variáveis Detalhes
    const [sistema_modulo, setSistema_modulo] = useState([{}]);
    const [sistema_inversor, setSistema_inversor] = useState([{}]);
    const [sistema_outros, setSistema_outros] = useState([{}]);
    const [total_sistema_autotransformador, setTotalSistema_autotransformador] = useState();
    const [sistema_autotransformador, setSistema_autotransformador] = useState([]);
    const [dimensionamento, setDimensionamento] = useState('');
    const [precoTotalFOB, setPrecoTotalFOB] = useState('');
    const [precoTotalCIF, setPrecoTotalCIF] = useState('');

    function BotaoImprimir(props) {
        if (props.impressaoBloqueada === false) {
            if (props.impressaoCarregando === true) {
                return <button disabled> <DynamicLoading width='40px' /> </button>
            }
            else {
                return <ProposalPrint propostaImpressa={props.dadosImpressao} dadosRoute={dadosRoute} setImpressaoCarregando={props.setImpressaoCarregando} />
            }
        } else {
            return <button disabled><span><FiPrinter/> Imprimir</span></button>
        }
    }

    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErroPersonalizado = (error) => {
        const mensagem_erro = (typeof error.response !== "undefined") ? error.response.data.error : "";

        toast.error(`Erro ao estabelecer conexão com o servidor ${mensagem_erro}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarSucessoAoSalvar = () => {
        toast.success('Proposta salva com sucesso!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErroPreenchimentoFormulario = () => {
        toast.warn('Fomulário incompleto ou inválido!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    
    function restringirBotoes() {
        setResumoAnaliseInvalido(true);
        setPropostaAlterada(true);
        setSalvarBloqueado(true);
        setImpressaoBloqueada(true);
    }
    
    async function gerarProposta() {
        setDone(false);
        setTabelaDone(false);

        setSalvarBloqueado(true);
        setImpressaoBloqueada(true);

        try {
            const send = {
                proposta_resumida: 1,
                tabela_considerada: '',
                comissao: estadoFormulario.comissao,
                consumo: estadoFormulario.curva_consumo,
                concessionaria: estadoFormulario.concessionaria,
                relacao_geracao: estadoFormulario.relacao_geracao,
                curva_geracao: estadoFormulario.curva_geracao,
                percentual_atendido: estadoFormulario.percentual_atendido,
                tipo_consumidor: "baixa_tensao",
                tarifa: estadoFormulario.tarifa,
                reajuste_tarifa_proposta: '',
                tma: '',
                taxa_disponibilidade: estadoFormulario.taxa_disponibilidade,
                valor_fixo: estadoFormulario.valor_fixo,
                injecao: estadoFormulario.injecao,
                icms_tarifa: estadoFormulario.icms_tarifa,
                parcela_tusd: estadoFormulario.parcela_tusd,
                parcela_fiob: estadoFormulario.parcela_fiob,
                fases: 3,
                rede_380: 0,
                smart_meter: 0,
                zero_export: 0,
                garantia_estendida: 0,
                substituicao_inversor_ano10: '',
                laudo_estrutural: 0,
                outros_valores: 0,
                modulo: {
                    modelo: estadoFormulario.modulo_modelo,
                    potencia: estadoFormulario.modulo_potencia,
                    area: estadoFormulario.modulo_area,
                    peso: estadoFormulario.modulo_peso,
                    garantia1: estadoFormulario.modulo_garantia1,
                    garantia2: estadoFormulario.modulo_garantia2,
                    campo_adicional1: estadoFormulario.modulo_campo_adicional1
                },
                fixacao: {
                    aplicacao_estrutura: estadoFormulario.aplicacao_estrutura,
                    tipo_estrutura: estadoFormulario.tipo_estrutura
                },
                frete: {
                    tipo_frete: '',
                    acrescimo_frete: ''
                },
                desconto_weg_proposta: estadoFormulario.desconto_weg_proposta,
                imposto_proposta: '',
                margem_proposta: '',
                material_proposta: '',
                tipo_inversor: estadoFormulario.tipo_inversor,
                status_proposta: estadoFormulario.status_proposta,
                id_responsavel: estadoFormulario.id_responsavel,
            };

            const response = await api.post('propostas/simular', send);

            setTabelaConsiderada(response.data.resumo.tabela_considerada)

            setSistema_modulo(response.data.itens.modulo);
            setSistema_inversor(response.data.itens.inversor);
            setSistema_outros(response.data.itens.outros);
            setSistema_autotransformador(response.data.itens.autotransformador);
            setTotalSistema_autotransformador(
                response.data.itens.autotransformador.length > 0 ?
                response.data.itens.autotransformador.map(x => (x.preco * x.quant)).reduce((acc, cur) =>  acc + cur) : 0
            );
            setPrecoTotalFOB(response.data.resumo.preco_kit);
            setPrecoTotalCIF(response.data.resumo.preco_kit_frete);

            setDimensionamento(response.data.resumo.dimensionamento);


            setResumo({
                sistema_necessario: response.data.resumo.sistema_necessario,
                sistema_sugerido: response.data.resumo.sistema_sugerido,
                conta_sem_sistema: response.data.analise.media_conta_antes_ano1,
                conta_com_sistema: response.data.analise.media_conta_depois_ano1,
                opex_fiob_icms_mensal_medio_ano1: response.data.analise.opex_fiob_icms_mensal_medio_ano1,
                economia: response.data.analise.media_economia_ano1,
                geracao_media: response.data.resumo.geracao_media,
                mao_obra_transporte: response.data.resumo.mao_obra_transporte,
                dias_obra: response.data.resumo.dias_obra,

                valor_final: response.data.resumo.valor_final,
                valor_final_kwp: response.data.resumo.valor_final_kwp,
                valor_comissao: response.data.resumo.valor_comissao,
                etapa1: response.data.resumo.etapa1,
                etapa2: response.data.resumo.etapa2,
                etapa3: response.data.resumo.etapa3,

                payback_string: response.data.analise.payback_string,
                parcial_produto: response.data.resumo.parcial_produto,
                parcial_produto_kwp: response.data.resumo.parcial_produto_kwp,
                parcial_servico: response.data.resumo.parcial_servico,
                parcial_servico_kwp: response.data.resumo.parcial_servico_kwp,

                degradacao_ano1: response.data.resumo.degradacao_ano1,
                degradacao_subsequente: response.data.resumo.degradacao_subsequente,
                icms_tarifa: response.data.resumo.icms_tarifa,

                potencia_total: response.data.resumo.potencia_total,
            });

            setAnalise({
                vpl_escalonado: response.data.analise.vpl_escalonado,
                tir_escalonado: response.data.analise.tir_escalonado,
                economia_escalonado: response.data.analise.economia_escalonado,
                geracao_ano1: response.data.analise.geracao_ano1,
                geracao_25anos: response.data.analise.geracao_25anos,
                fluxo_acumulado_25anos: response.data.analise.fluxo_acumulado_25anos,
                credito_ano1: response.data.analise.credito_ano1,
                lcoe: response.data.analise.lcoe
            })

            setDone(true);
            setTabelaDone(true);

            setResumoAnaliseInvalido(false);

            setSalvarBloqueado(false);
            
        } catch (error) {
            notificarErroPersonalizado(error);

            setDone(true);
            setTabelaDone(true);
            setResumoAnaliseInvalido(true);

            setSalvarBloqueado(true);
        }
        
    }

    function identificacaoProposta(proposta) {
        try {
            const ID_cliente = proposta.ID_cliente
            const titulo_cliente = proposta.titulo_cliente
            const nome_razao_cliente = proposta.nome_razao_cliente
            let vocativo_definido;
            
            setClientID(ID_cliente);

            if (titulo_cliente === "Sr." || titulo_cliente === "Sra.") {
                vocativo_definido = `${titulo_cliente} ${nome_razao_cliente}`;
                setVocativo(vocativo_definido);
                
            } else if (titulo_cliente === "N/A"){
                vocativo_definido = nome_razao_cliente;
                setVocativo(vocativo_definido);
            }

        } catch (error) {
            history.push('/propostas_completas');
        }
    }

    function tituloPagina(proposta) {
        let vocativo_definido;

        if (proposta !== undefined && proposta.titulo_cliente !== undefined) {
            if (proposta.titulo_cliente === "Sr." || proposta.titulo_cliente === "Sra.") {
                vocativo_definido = `${proposta.titulo_cliente} ${proposta.nome_razao_cliente}`;
            } else if (proposta.titulo_cliente === "N/A"){
                vocativo_definido = proposta.nome_razao_cliente;
            }
    
            if (proposta.numero_proposta !== undefined && proposta.revisao_proposta !== undefined && vocativo_definido !== undefined) {
                if (proposta.revisao_proposta > 1) {
                    document.title = `${proposta.numero_proposta}_v${proposta.revisao_proposta} ${vocativo_definido}`
                } else {
                    document.title = `${proposta.numero_proposta} ${vocativo_definido}`
                }
                
            } else {
                proposta.numero_proposta === undefined && console.log('erro: ' + 'proposta.numero_proposta')
                proposta.revisao_proposta === undefined && console.log('erro: ' + 'proposta.revisao_proposta')
                vocativo_definido === undefined &&  console.log('erro: ' + 'vocativo_definido')
            }
        }
    }

    function verificarPropostaExistente(proposta_salva) {
        try {
            if (proposta_salva !== undefined) {
                setSalvarBloqueado(true);
                setImpressaoBloqueada(false);

                setNovaProposta(false);

                setActionTitle('VISUALIZAR PROPOSTA');

                setTabelaConsiderada(proposta_salva.tabela_considerada)

                setNumero_proposta((proposta_salva.numero_proposta));
                setRevisao_proposta(proposta_salva.revisao_proposta);
                setData_proposta_string((new Date(proposta_salva.data_apresentacao_proposta)).toLocaleDateString());

                setDadosImpressao(proposta_salva);

                const valor_comissao = proposta_salva.valor_final * proposta_salva.comissao_proposta / 100;

                setResumo({
                    sistema_necessario: proposta_salva.sistema_necessario,
                    sistema_sugerido: proposta_salva.sistema_sugerido,
                    conta_sem_sistema: proposta_salva.conta_sem_sistema,
                    conta_com_sistema: proposta_salva.conta_com_sistema,
                    opex_fiob_icms_mensal_medio_ano1: proposta_salva.opex_fiob_icms_mensal_medio_ano1,
                    economia: proposta_salva.economia,
                    geracao_media: proposta_salva.geracao_media,
    
                    valor_final: proposta_salva.valor_final,
                    valor_final_kwp: proposta_salva.valor_final_kwp,
                    valor_comissao: valor_comissao,
                    etapa1: proposta_salva.etapa1,
                    etapa2: proposta_salva.etapa2,
                    etapa3: proposta_salva.etapa3,
    
                    payback_string: proposta_salva.payback_string,

                    parcial_produto: proposta_salva.parcial_produto,
                    parcial_produto_kwp: proposta_salva.parcial_produto_kwp,
                    parcial_servico: proposta_salva.parcial_servico,
                    parcial_servico_kwp: proposta_salva.parcial_servico_kwp,

                    degradacao_ano1: proposta_salva.degradacao_ano1,
                    degradacao_subsequente: proposta_salva.degradacao_subsequente,
                    icms_tarifa: proposta_salva.icms_tarifa,

                    potencia_total: proposta_salva.potencia_total,
                    mao_obra_transporte: proposta_salva.mao_obra_transporte,
                    dias_obra: proposta_salva.dias_obra
                });

                setAnalise({
                    vpl_escalonado: proposta_salva.vpl_escalonado,
                    tir_escalonado: proposta_salva.tir_escalonado,
                    economia_escalonado: proposta_salva.economia_escalonado,
                    geracao_ano1: proposta_salva.geracao_ano1,
                    geracao_25anos: proposta_salva.geracao_25anos,
                    fluxo_acumulado_25anos: proposta_salva.fluxo_acumulado_25anos,
                    credito_ano1: proposta_salva.credito_ano1,
                    lcoe: proposta_salva.lcoe
                })

                setSistema_modulo(proposta_salva.sistema_modulo);
                setSistema_inversor(proposta_salva.sistema_inversor);
                setSistema_outros(proposta_salva.sistema_outros);
                setSistema_autotransformador(proposta_salva.sistema_autotransformador);
                setTotalSistema_autotransformador(
                    proposta_salva.sistema_autotransformador.length > 0 ?
                    proposta_salva.sistema_autotransformador.map(x => (x.preco * x.quant)).reduce((acc, cur) =>  acc + cur) : 0
                );
                setDimensionamento(proposta_salva.dimensionamento);
                setPrecoTotalFOB(proposta_salva.valor_kit);
                setPrecoTotalCIF(proposta_salva.valor_kit_frete);

                // Variável enviada para 'Form.js'
                setPropostaExistente(proposta_salva);
                setResumoAnaliseInvalido(false);
            } else {
                setActionTitle('NOVA PROPOSTA');
            }

        } catch (error) {  }
    }

    async function salvarProposta(numero_proposta_param = 0, revisao_proposta_param = 0) {
        setSalvarBloqueado(true);
        setImpressaoBloqueada(true);

        setSalvarCarregando(true);

        let bool_sucesso = false;

        if (!permissaoUsuario.verificar(50, token.tipo_usuario)) {
            numero_proposta_param = numero_proposta;
            revisao_proposta_param = revisao_proposta;
        }

        try {
            if (novaProposta === true || !permissaoUsuario.verificar(50, token.tipo_usuario)) {
                // POST
                const send = {
                    numero_proposta: numero_proposta_param,
                    revisao_proposta: revisao_proposta_param,
                    proposta_resumida: 1,
                    id_cliente_proposta: clientID,
                    concessionaria_proposta: estadoFormulario.concessionaria,
                    id_usuario_responsavel_proposta: estadoFormulario.id_responsavel,

                    comissao_proposta: estadoFormulario.comissao,
                    tarifa_proposta: estadoFormulario.tarifa,
                    reajuste_tarifa_proposta: '',
                    tma: '',

                    taxa_disponibilidade: estadoFormulario.taxa_disponibilidade,

                    injecao: estadoFormulario.injecao,
                    icms_tarifa: estadoFormulario.icms_tarifa,
                    parcela_tusd: estadoFormulario.parcela_tusd,
                    parcela_fiob: estadoFormulario.parcela_fiob,

                    valor_fixo: estadoFormulario.valor_fixo,
                    aplicacao_estrutura: estadoFormulario.aplicacao_estrutura,
                    
                    tipo_estrutura: estadoFormulario.tipo_estrutura,
                    tipo_frete: '',
                    acrescimo_frete: '',
                    laudo_estrutural: '',
                    
                    rede_380: '',
                    smart_meter: '',
                    zero_export: '',
                    garantia_estendida: '',
                    
                    substituicao_inversor_ano10: '',
                    outros_valores: 0,
                    mao_obra_transporte: resumo.mao_obra_transporte,
                    dias_obra: resumo.dias_obra,
                    percentual_atendido: estadoFormulario.percentual_atendido,
                    tabela_considerada: '',
                    
                    consumo: estadoFormulario.curva_consumo,
                    fases: 3,
                    relacao_geracao: estadoFormulario.relacao_geracao,
                    geracao: estadoFormulario.curva_geracao,

                    sistema_necessario: resumo.sistema_necessario,
                    sistema_sugerido: resumo.sistema_sugerido,
                    sistema_modulo: sistema_modulo,
                    sistema_inversor: sistema_inversor,
                    
                    sistema_outros: sistema_outros,
                    sistema_autotransformador: sistema_autotransformador,
                    sistema_garantia_estendida: '0',
                    dimensionamento: dimensionamento,
                    valor_kit: precoTotalFOB,
                    
                    valor_kit_frete: precoTotalCIF,
                    etapa1: resumo.etapa1,
                    etapa2: resumo.etapa2,
                    etapa3: resumo.etapa3,

                    valor_final: resumo.valor_final,
                    valor_final_kwp: resumo.valor_final_kwp,
                    conta_sem_sistema: resumo.conta_sem_sistema,
                    conta_com_sistema: resumo.conta_com_sistema,
                    
                    opex_fiob_icms_mensal_medio_ano1: resumo.opex_fiob_icms_mensal_medio_ano1,
                    economia: resumo.economia,
                    geracao_media: resumo.geracao_media,

                    parcial_produto: resumo.parcial_produto,
                    parcial_produto_kwp: resumo.parcial_produto_kwp,
                    parcial_servico: resumo.parcial_servico,
                    parcial_servico_kwp: resumo.parcial_servico_kwp,

                    payback_string: resumo.payback_string,
                    
                    vpl_escalonado: analise.vpl_escalonado,
                    tir_escalonado: analise.tir_escalonado,
                    economia_escalonado: analise.economia_escalonado,
                    desconto_weg_proposta: estadoFormulario.desconto_weg_proposta,
                   
                    imposto_proposta: '',
                    margem_proposta: '',
                    material_proposta: '',
                    
                    degradacao_ano1: resumo.degradacao_ano1,
                    degradacao_subsequente: resumo.degradacao_subsequente,

                    geracao_ano1: analise.geracao_ano1,
                    geracao_25anos: analise.geracao_25anos,
                    fluxo_acumulado_25anos: analise.fluxo_acumulado_25anos,
                    credito_ano1: analise.credito_ano1,
                    potencia_total: resumo.potencia_total,
                    lcoe: analise.lcoe,

                    tipo_inversor: estadoFormulario.tipo_inversor,
                    status_proposta: estadoFormulario.status_proposta
                    
                };

                const result = await api.post('propostas', send);

                identificacaoProposta(result.data);
                verificarPropostaExistente(result.data);

                tituloPagina(result.data);

                setNovaProposta(false);

            } else if (permissaoUsuario.verificar(50, token.tipo_usuario)) {
                // PATCH
                const send = {
                    id_cliente_proposta: clientID,
                    concessionaria_proposta: estadoFormulario.concessionaria,
                    id_usuario_responsavel_proposta: estadoFormulario.id_responsavel,
                    comissao_proposta: estadoFormulario.comissao,
                    tarifa_proposta: estadoFormulario.tarifa,
                    reajuste_tarifa_proposta: 8,
                    tma: '',
                    taxa_disponibilidade: estadoFormulario.taxa_disponibilidade,
                    injecao: estadoFormulario.injecao,
                    icms_tarifa: estadoFormulario.icms_tarifa,
                    parcela_tusd: estadoFormulario.parcela_tusd,
                    parcela_fiob: estadoFormulario.parcela_fiob,
                    valor_fixo: estadoFormulario.valor_fixo,
                    aplicacao_estrutura: estadoFormulario.aplicacao_estrutura,
                    tipo_estrutura: estadoFormulario.tipo_estrutura,
                    laudo_estrutural: estadoFormulario.laudo_estrutural,
                    rede_380: estadoFormulario.rede_380,
                    smart_meter: estadoFormulario.smart_meter,
                    zero_export: estadoFormulario.zero_export,
                    garantia_estendida: 0,
                    substituicao_inversor_ano10: estadoFormulario.substituicao_inversor_ano10,
                    outros_valores: estadoFormulario.outros_valores,
                    mao_obra_transporte: resumo.mao_obra_transporte,
                    dias_obra: resumo.dias_obra,
                    percentual_atendido: estadoFormulario.percentual_atendido,
                    tabela_considerada: estadoFormulario.tabela,
                    consumo: estadoFormulario.curva_consumo,
                    fases: 3,
                    relacao_geracao: estadoFormulario.relacao_geracao,
                    geracao: estadoFormulario.curva_geracao,
                    sistema_necessario: resumo.sistema_necessario,
                    sistema_sugerido: resumo.sistema_necessario,
                    sistema_modulo: sistema_modulo,
                    sistema_inversor: sistema_inversor,
                    sistema_outros: sistema_outros,
                    sistema_autotransformador: sistema_autotransformador,
                    sistema_garantia_estendida: '0',
                    dimensionamento: dimensionamento,
                    valor_kit: precoTotalFOB,
                    valor_kit_frete: precoTotalCIF,
                    etapa1: resumo.etapa1,
                    etapa2: resumo.etapa2,
                    etapa3: resumo.etapa3,
                    valor_final: resumo.valor_final,
                    valor_final_kwp: resumo.valor_final_kwp,
                    conta_sem_sistema: resumo.conta_sem_sistema,
                    conta_com_sistema: resumo.conta_com_sistema,
                    opex_fiob_icms_mensal_medio_ano1: resumo.opex_fiob_icms_mensal_medio_ano1,
                    economia: resumo.economia,
                    geracao_media: resumo.geracao_media,
                    parcial_produto: resumo.parcial_produto,
                    parcial_produto_kwp: resumo.parcial_produto_kwp,
                    parcial_servico: resumo.parcial_servico,
                    parcial_servico_kwp: resumo.parcial_servico_kwp,
                    payback_string: resumo.payback_string,
                    vpl_escalonado: analise.vpl_escalonado,
                    tir_escalonado: analise.tir_escalonado,
                    economia_escalonado: analise.economia_escalonado,
                    desconto_weg_proposta: estadoFormulario.desconto_weg_proposta,
                    imposto_proposta: estadoFormulario.imposto_proposta,
                    margem_proposta: estadoFormulario.margem_proposta,
                    material_proposta: estadoFormulario.material_proposta,
                    degradacao_ano1: resumo.degradacao_ano1,
                    degradacao_subsequente: resumo.degradacao_subsequente,
                    geracao_ano1: analise.geracao_ano1,
                    geracao_25anos: analise.geracao_25anos,
                    fluxo_acumulado_25anos: analise.fluxo_acumulado_25anos,
                    credito_ano1: analise.credito_ano1,
                    potencia_total: resumo.potencia_total,
                    lcoe: analise.lcoe,                    
                    tipo_inversor: estadoFormulario.tipo_inversor,
                    status_proposta: estadoFormulario.status_proposta,
                    
                    numero_proposta: numero_proposta,
                    revisao_proposta: revisao_proposta
                };
                
                const result = await api.patch('propostas', send);
                
                verificarPropostaExistente(result.data);
            }
            notificarSucessoAoSalvar();

            setPropostaAlterada(false);
            setSalvarBloqueado(true);
            setImpressaoBloqueada(false);
           
            bool_sucesso = true;

        } catch (error) {
            notificarErroPersonalizado(error);

            setSalvarCarregando(false);
            setSalvarBloqueado(false);
            setImpressaoBloqueada(true);
        }

        setSalvarCarregando(false);
        return bool_sucesso;

    }

    useEffect(() => {
        document.title = `SGC Brazil Solution`;

        const tokenJSON = JSON.parse(localStorage.getItem('sgc_br'));
        setToken(tokenJSON);

        async function carregarDados() {
            setDone(false);
            setIsSubmitted(true);
    
            // Verificar privilégios
            let ret = userType();

            try {
                const response = await api.get('dados');

                setDadosRoute(response.data);
                setTabelaConsiderada(response.data.tabela_vigente.chave);
                setTabelaVigente(response.data.tabela_vigente.chave);

                const comissao = parseFloat(response.data.responsaveis[0].comissao_usuario.toFixed(2));
                setEstadoFormulario({
                    ...estadoFormulario,
                    comissao: comissao,
                    injecao: response.data.informacao_complementar_float.injecao_padrao.valor * 100,
                });
    
                identificacaoProposta(props.location.data);
                verificarPropostaExistente(props.location.proposal);

                tituloPagina(props.location.proposal);
                
            } catch (error) {
                notificarErroPersonalizado(error);
            }

            setDone(true);
            setTabelaDone(true);
            setIsSubmitted(false);
        }
        
        carregarDados();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <div className="container">
            <Header />
            <ToastContainer />

            {modalSalvarComoAberto && <ModalSalvarComo
                setModalSalvarComoAberto={setModalSalvarComoAberto}
                salvarProposta={salvarProposta}
                salvarCarregando={salvarCarregando}
            />}
            
            <div className="body-container-2">
                <div className="type1-element">
                    <div className="type1-top-line">
                        <div className="type1-title">
                            {actionTitle}
                        </div>
                        {tabelaConsiderada !== tabelaVigente ? (
                            <div style={{marginLeft: 'auto'}} className="alerta-tabela-tipo2">
                            <p>
                                Atenção! Lista de preços desatualizada
                            </p>
                            <span>
                                <FiAlertTriangle size={18} />
                            </span>
                            </div>
                        ) : (<></>)}
                    </div>
                    <div className="proposal-client-title">
                        <table>
                            <thead>
                                {numero_proposta === 0 ? (
                                    <tr>
                                        <th>Cliente:</th><td>{vocativo}</td>
                                    </tr>
                                ) : (
                                    <>
                                        <tr>
                                            <th>Cliente:</th><td colSpan="3">{vocativo}</td>
                                        </tr>
                                        <tr>
                                            <th>Nº proposta:</th><td>{numero_proposta}</td><th>Versão:</th><td>{revisao_proposta}ª</td>
                                        </tr>
                                        <tr>
                                            <th>Data:</th><td colSpan="3">{data_proposta_string}</td>
                                        </tr>
                                    </>
                                )}
                            </thead>
                        </table>

                        <div className="proposal-element-body-functions-resumida">
                            {/* Modal para confirmar a saída da página. */}
                            { modalVoltarAberto && <ModalVoltar voltar={() => history.push('/propostas')} setModalVoltarAberto={setModalVoltarAberto} /> }
                            
                            {/* Botão para sair da página. Pode desencadear o modal de confirmação ou não. */}
                            <div className="proposal-button-resumida"> <button onClick={() => {propostaAlterada ? setModalVoltarAberto(true) : history.push('/propostas');}}> <span>Voltar</span> </button> </div>
                            
                            {salvarCarregando ? (
                                <div className="proposal-button-resumida">
                                    <button disabled> <DynamicLoading width='40px' /> </button>
                                </div>
                            )  :
                            permissaoUsuario.verificar(50, token.tipo_usuario) && props.location.proposal === undefined ? (
                                <BotaoSalvarComo setModalSalvarComoAberto={setModalSalvarComoAberto} notificarErro={notificarErro} notificarErroPersonalizado={notificarErroPersonalizado} salvarCarregando={salvarCarregando} salvarProposta={salvarProposta} salvarBloqueado={salvarBloqueado} />
                            ) : (
                                <div className="proposal-button-resumida">
                                    <button onClick={() => salvarProposta()} disabled={salvarBloqueado}><span>Salvar</span></button>
                                </div>
                            )}

                            <div className="proposal-button-resumida">
                                <BotaoImprimir
                                    impressaoBloqueada={impressaoBloqueada}
                                    impressaoCarregando={impressaoCarregando}
                                    setImpressaoCarregando={setImpressaoCarregando}
                                    dadosImpressao={dadosImpressao}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="proposal-container">
                    <div className="proposal-element-narrow-wrapper">
                        {!isSubmitted ? (
                            <Form
                                // Funções
                                setEstadoFormulario={setEstadoFormulario}
                                gerarProposta={gerarProposta}
                                restringirBotoes={restringirBotoes}
                                notificarErroPreenchimentoFormulario={notificarErroPreenchimentoFormulario}

                                // Valores
                                estadoFormulario={estadoFormulario}
                                propostaExistente={propostaExistente}
                                dadosRoute={dadosRoute}
                                resumo={resumo}
                                done={done}
                                token={token}
                            />
                        ) : (
                            <FormLoading />
                        )}
                    </div>
                    
                    <div className="proposal-element-wide-wrapper">
                        <Tabs>
                            <TabList>
                                <Tab>Kit considerado</Tab>
                            </TabList>

                            <div className="proposal-subelement">
                                <div className="proposal-element-body">

                                    <TabPanel>
                                        {!done ? (
                                            <div>
                                                <Loading />
                                            </div>
                                        ) : (permissaoUsuario.verificar(50, token.tipo_usuario) || token.tipo_usuario === 'suportevendas1') ? (
                                            <>
                                                Sistema fotovoltaico
                                                <table className="table-kit">

                                                    <thead>
                                                        <tr>
                                                            <th className="table-kit-narrow">Quantidade</th>
                                                            <th>Item</th>
                                                            <th className="table-kit-narrow">Preço Unit.</th>
                                                            <th className="table-kit-narrow round-br">Subtotal</th>
                                                        </tr>
                                                    </thead>
                                                    
                                                    <tbody>
                                                        {sistema_modulo.map((item, indice) => {
                                                            return item.quant === null ?
                                                            <></>
                                                            : item.quant === 0 ?
                                                            <></>
                                                            :
                                                            <tr key={indice}>
                                                                <td>{parseFloat(item.quant).toLocaleString('pt-BR', {style: 'decimal'})}</td>
                                                                <td>{item.modelo}</td>
                                                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.preco)}</td>
                                                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.quant * item.preco)}</td>
                                                            </tr>
                                                        })}

                                                        {sistema_inversor.map(item => {
                                                            return item.quant == null ?
                                                            <></>
                                                            : item.quant === 0 ?
                                                            <></>
                                                            :
                                                            <tr key={item.modelo}>
                                                                <td>{parseFloat(item.quant).toLocaleString('pt-BR', {style: 'decimal'})}</td>
                                                                <td>{item.modelo}</td>
                                                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.preco)}</td>
                                                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.quant * item.preco)}</td>
                                                            </tr>
                                                        })}

                                                        {sistema_outros.map(item => {
                                                            return item.quant == null ?
                                                            <></>
                                                            : item.quant === 0 ?
                                                            <></>
                                                            :
                                                            <tr key={item.modelo}>
                                                                <td>{parseFloat(item.quant).toLocaleString('pt-BR', {style: 'decimal'})}</td>
                                                                <td>{item.modelo}</td>
                                                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.preco)}</td>
                                                                <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.quant * item.preco)}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>

                                                <div className="proposal-same-line-tables">
                                                    <div className="proposal-table-dimensioning">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="table-kit-wide">Dimensionamento</th>
                                                                    <td className="table-kit-narrow">{dimensionamento}</td>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>

                                                    
                                                    <div className="proposal-table-total">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="table-50p">Total</th>
                                                                    <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(precoTotalFOB)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className="table-50p">Total com frete</th>
                                                                    <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(precoTotalCIF)}</td>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>
                                                </div>

                                                
                                                {sistema_autotransformador.length > 0 &&
                                                    <>
                                                        <div className="titulo_autotransformadores">
                                                            Autotransformador
                                                        </div>
                                                        <table className="table-kit" >
                                                            <thead>
                                                                <tr>
                                                                    <th className="table-kit-narrow">Quantidade</th>
                                                                    <th>Item</th>
                                                                    <th className="table-kit-narrow">Preço Unit.</th>
                                                                    <th className="table-kit-narrow round-br">Subtotal</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {sistema_autotransformador.map(item => {
                                                                    return (item.quant !== null && item.quant !== 0) &&
                                                                    <tr key={item.modelo}>
                                                                        <td>{item.quant}</td>
                                                                        <td>{item.modelo}</td>
                                                                        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.preco)}</td>
                                                                        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.quant * item.preco)}</td>
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        
                                                                
                                                        <div className="proposal-table-total">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="table-50p">Total autotr.</th>
                                                                        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(sistema_autotransformador.map(x => (x.preco * x.quant)).reduce((acc, cur) =>  acc + cur))}</td>
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                    </>
                                                }

                                                <br />
                                                <br />
                                                <br />

                                                <div className="proposal-table-total">
                                                    <table>
                                                        <thead>
                                                            <tr className="valor_final">
                                                                <th className="table-50p">Valor Final</th>
                                                                <th>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.valor_final)}</th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                Sistema fotovoltaico
                                                <table className="table-kit" >
                                                    <thead>
                                                        <tr>
                                                            <th className="table-kit-narrow">Quantidade</th>
                                                            <th>Item</th>
                                                        </tr>
                                                    </thead>
                                                    
                                                    <tbody>
                                                        {sistema_modulo.map((item, indice) => {
                                                            return item.quant === null ?
                                                            <tr key="0"></tr>
                                                            : item.quant === 0 ?
                                                            <tr key="0"></tr>
                                                            :
                                                            <tr key={indice}>
                                                                <td>{parseFloat(item.quant).toLocaleString('pt-BR', {style: 'decimal'})}</td>
                                                                <td>{item.modelo}</td>
                                                            </tr>
                                                        })}

                                                        {sistema_inversor.map(item => {
                                                            return item.quant == null ?
                                                            <tr key="0"></tr>
                                                            : item.quant === 0 ?
                                                            <tr key="0"></tr>
                                                            :
                                                            <tr key={item.modelo}>
                                                                <td>{parseFloat(item.quant).toLocaleString('pt-BR', {style: 'decimal'})}</td>
                                                                <td>{item.modelo}</td>
                                                            </tr>
                                                        })}

                                                        {sistema_outros.map(item => {
                                                            return item.quant == null ?
                                                            <tr key="0"></tr>
                                                            : item.quant === 0 ?
                                                            <tr key="0"></tr>
                                                            :
                                                            <tr key={item.modelo}>
                                                                <td>{parseFloat(item.quant).toLocaleString('pt-BR', {style: 'decimal'})}</td>
                                                                <td>{item.modelo}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>

                                                <div className="proposal-same-line-tables">
                                                    <div className="proposal-table-dimensioning">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="table-kit-wide">Dimensionamento</th>
                                                                    <td className="table-kit-narrow">{dimensionamento}</td>
                                                                </tr>
                                                            </thead>
                                                        </table>
                                                    </div>

                                                    {permissaoUsuario.verificar(50, token.tipo_usuario) &&
                                                        <div className="proposal-table-total">
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="table-50p">Total com frete</th>
                                                                        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(precoTotalCIF)}</td>
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                    }

                                                </div>

                                                {sistema_autotransformador.length > 0 &&
                                                    <>
                                                        <div className="titulo_autotransformadores">
                                                            Autotransformador
                                                        </div>
                                                        <table className="table-kit" >
                                                            <thead>
                                                                <tr>
                                                                    <th className="table-kit-narrow">Quantidade</th>
                                                                    <th>Item</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {sistema_autotransformador.map(item => {
                                                                    return (item.quant !== null && item.quant !== 0) &&
                                                                    <tr key={item.modelo}>
                                                                        <td>{item.quant}</td>
                                                                        <td>{item.modelo}</td>
                                                                    </tr>
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </>
                                                }

                                                <br />
                                                <br />
                                                <br />

                                                <div className="proposal-table-total">
                                                    <table>
                                                        <thead>
                                                            <tr className="valor_final">
                                                                <th className="table-50p">Valor Final</th>
                                                                <th>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.valor_final)}</th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                </div>

                                            </>
                                        )}
                                    </TabPanel>
                                
                                
                                </div>
                            </div>
                        </Tabs>
                    </div>
                    
                    <div className="proposal-element-narrow-wrapper">
                        <Tabs>
                            <TabList>
                                <Tab>Resumo</Tab>
                                <Tab>Retorno Financeiro</Tab>
                            </TabList>

                            <div className="proposal-subelement">
                                <div className="proposal-element-body">
                                    <TabPanel>
                                        <Resumo
                                            token={token}
                                            done={done}
                                            resumoAnaliseInvalido={resumoAnaliseInvalido}
                                            total_sistema_autotransformador={total_sistema_autotransformador}
                                            resumo={resumo}
                                            estadoFormulario={estadoFormulario}
                                            dadosRoute={dadosRoute}
                                            sistema_modulo={sistema_modulo}
                                            sistema_inversor={sistema_inversor}
                                        />
                                    </TabPanel>

                                    <TabPanel>
                                        <RetornoFinanceiro resumoAnaliseInvalido={resumoAnaliseInvalido} done={done} analise={analise} />
                                    </TabPanel>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PropostasResumidasSimulacao;