// Referências externas
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Referências internas
import { Header, Loading } from '../../common';
import api from '../../services/api';

// Estilo
import './styles.css';


export default function Start() {
    const [done, setDone] = useState(false);
    const [resumo, setResumo] = useState({});
    const [anoCorrente, setAnoCorrente] = useState();
    const [mesCorrente, setMesCorrente] = useState();

    const notificarErro = () => {
        toast.error('Erro ao estabelecer conexão com o servidor', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const notificarErroPersonalizado = (error) => {
        const mensagem_erro = (typeof error.response !== "undefined") ? error.response.data.error : "";

        toast.error(`Erro ao estabelecer conexão com o servidor ${mensagem_erro}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    useEffect(() => {
        document.title = `SGC Brazil Solution`;

        async function solicitarResumo() {
            setDone(false);
        
            try {
                const response = await api.get('propostas/resumo');
                
                setResumo(response.data);

                setMesCorrente(response.data.mes)
                setAnoCorrente(response.data.ano)

                setDone(true);
    
            } catch (error) {
                notificarErroPersonalizado(error);
            }

        }

        solicitarResumo();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container">
            <Header />
            <ToastContainer />

            <div className="container">

            <div className="body-container-2">
                <div className="type1-element">
                    <div className="type1-top-line">
                        <div className="type1-title">
                            Resumo
                        </div>
                    </div>
                    {!done ? (
                        <div>
                            <Loading />
                        </div>
                            
                    )  :  (
                        <div className="summary-wrap">
                            <div className="summary1-table-wrap">
                                <table className="collapse">
                                    <thead>
                                        <tr>
                                            <th>Agente Comercial</th>
                                            <th>Total</th>
                                            <th>{anoCorrente}</th>
                                            <th>{mesCorrente + '/' + anoCorrente}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resumo.usuario_total.map((usuario, indice) => {
                                            if (indice % 2 === 0 && indice >= 0) {
                                                return (
                                                    <tr key={usuario.nome_usuario} className="linha-background-cinza">
                                                        <td>{usuario.nome_usuario}</td>
                                                        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(usuario.valor_total)}</td>
                                                        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.usuario_ano_corrente[indice].valor_total)}</td>
                                                        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.usuario_mes_corrente[indice].valor_total)}</td>
                                                    </tr>
                                                )
                                            } else {
                                                return (
                                                    <tr key={usuario.nome_usuario}>
                                                        <td>{usuario.nome_usuario}</td>
                                                        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(usuario.valor_total)}</td>
                                                        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.usuario_ano_corrente[indice].valor_total)}</td>
                                                        <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.usuario_mes_corrente[indice].valor_total)}</td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="summary2-table-wrap">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Parâmetro</th>
                                            <th>Total</th>
                                            <th>{anoCorrente}</th>
                                            <th>{mesCorrente + '/' + anoCorrente}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>Total</td>
                                            <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.empresa_total.valor_total)}</td>
                                            <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.empresa_ano_corrente.valor_total)}</td>
                                            <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.empresa_mes_corrente.valor_total)}</td>
                                        </tr>
                                        <tr>
                                            <td>Média</td>
                                            <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.empresa_total.valor_medio)}</td>
                                            <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.empresa_ano_corrente.valor_medio)}</td>
                                            <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.empresa_mes_corrente.valor_medio)}</td>
                                        </tr>
                                        <tr>
                                            <td>Valor/kWp</td>
                                            <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.empresa_total.valor_kwp_medio)}</td>
                                            <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.empresa_ano_corrente.valor_kwp_medio)}</td>
                                            <td>{Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(resumo.empresa_mes_corrente.valor_kwp_medio)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        </div>
    );
}