// Referências externas
import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import { FiPrinter } from 'react-icons/fi';
import { Bar, Line } from 'react-chartjs-2';
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Referências internas
import pagina1 from './print_images/Prancheta 1.svg';
import pagina2 from './print_images/Prancheta 2.svg';
import pagina3 from './print_images/Prancheta 3.svg';
import pagina4 from './print_images/Prancheta 4.svg';
import pagina5 from './print_images/Prancheta 5.svg';
import pagina6 from './print_images/Prancheta 6.svg';

import SIW100G from './print_images/SIW100G.jpg';
import goodweM from './print_images/goodweM.jpg';
import goodweT from './print_images/goodweT.jpg';
import huaweiM from './print_images/huaweiM.jpg';
import huaweiA from './print_images/huaweiA.jpg';
import huaweiB from './print_images/huaweiB.jpg';
import huaweiC from './print_images/huaweiC.jpg';
import huaweiZ2 from './print_images/huaweiZ2.jpg';
import huaweiZ3 from './print_images/huaweiZ3.jpg';
import huaweiZ4 from './print_images/huaweiZ4.jpg';
import huaweiZ5 from './print_images/huaweiZ5.jpg';
import wegMWF from './print_images/wegMWF.jpg';
import wegMWG5 from './print_images/wegMWG5.jpg';
import wegMWG7 from './print_images/wegMWG7.jpg';
import wegMWG25 from './print_images/wegMWG25.jpg';
import wegMWG100 from './print_images/wegMWG100.jpg';
import wegMWG175 from './print_images/wegMWG175.jpg';

import imagemErro from './print_images/erro.png';


// Estilo
import "./styles.css";
import "./pagina1.css";
import "./pagina3.css";
import "./pagina4.css";
import "./pagina5.css";
import "./pagina6.css";

Chart.register(ChartDataLabels);

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identificacao_proposta: 0,
      data_proposta: 0,
      titulo_cliente: 'ERRO',
      economia_capa: 0,
      economia: 0,
      lista_resumo_capa: [
        "ERRO",
        "ERRO",
        "ERRO",
        "ERRO",
        "ERRO"
      ],
      lcoe: 0,
      premissas: [
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO"
      ],
      dados_sistema: [
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO"
      ],
      estimativas: [
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO"
      ],
      analise_investimento: [
          "ERRO",
          "ERRO",
          "ERRO"
      ],
      tir_escalonado: [
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO"
      ],
      vpl_escalonado: [
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO"
      ],
      tma: 0,
      impacto_ambiental: [
          "ERRO",
          "ERRO"
      ],
      
      payback: {
        datasets: [{
            data: [26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, -5],
            fill: false,

            borderColor: '#406DB4',
            hoverBackgroundColor: '#406DB4',
            hoverBorderColor: '#406DB4',
            pointBackgroundColor: '#406DB4',
            pointBorderColor: '#406DB4',
            pointHoverBackgroundColor: '#406DB4',
            pointHoverBorderColor: '#406DB4',

            pointHoverBorderWidth: 1,
            pointHoverRadius: 3,

            datalabels: { display: false }
        }],
      },

      options_payback: {
        devicePixelRatio: 3,
        plugins: {
          title: {
            display: false
          },

          legend: {
            display: false,
          },

          tooltips: {
            enabled: false
          }
        },
        animation: {
          duration: 0,
          hover: { animationDuration: 0 },
          responsiveAnimationDuration: 0
        },
        maintainAspectRatio: false,
        layout: {
          padding: {
            right: 20,
            bottom: 44
          }
        },
        scales: {
          y: {
            grace: '5%',
            ticks: {
              font: {
                family: 'Noto Sans, sans-serif',
                size: 11.25,
              },
              color: '#034876',
              padding: 15,
              maxTicksLimit: 12,
              callback: function(value, index, values) {
                return 'R$ ' + (value).toLocaleString('pt-BR') + ',00';
              }
            },
            grid:{
              color: '#D9D9D9',
              drawBorder: false,
              drawTicks: false
            },
          },
          x: {
            labels: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25],
            ticks: {
              font: {
                family: 'Noto Sans, sans-serif',
                size: 10,
              },
              color: '#595959',
              padding: 10,
            },
            grid: {
              color: '#D9D9D9',
              drawOnChartArea: false,
              drawTicks: false,
              drawBorder: false
            },
            title: {
              display: true,
              text: "ANOS",
              padding: 0,
              color: '#595959',
              font: {
                family: 'Noto Sans, sans-serif',
                size: 10,
              },
            }
          }
        }
      },

      previsao: {
        labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        datasets: [{
          // Créditos
          type: 'line',
          label: 'Crédito Acumulado',
          fill: false,

          hoverBackgroundColor: '#1DA84C',

          borderColor: '#1DA84C',
          hoverBorderColor: '#1DA84C',

          pointBackgroundColor: '#1DA84C',
          pointHoverBackgroundColor: '#1DA84C',

          pointBorderColor: '#FFFFFF',
          pointHoverBorderColor: '#FFFFFF',

          pointBorderWidth: 1,
          pointRadius: 3.0,

          pointHoverBorderWidth: 1.5,
          pointHoverRadius: 3.0,

          data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
          datalabels: { display: false }
        },{
          // Consumo
          barThickness: 10,
          type: 'bar',
          label: 'Consumo',
          hoverBackgroundColor: '#A6A6A6',
          backgroundColor: '#A6A6A6',
          data: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
          datalabels: { display: false },
          borderColor: 'white',
          pointStyle: 'rect',
        },{
          // Geração
          barThickness: 10,
          type: 'bar',
          label: 'Geração',
          hoverBackgroundColor: '#FCBE00',
          backgroundColor: '#FCBE00',
          data: [15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15],
          datalabels: {
            align: '275',
            anchor: 'end',
            offset: 4,
            color: 'black',
            font: {
              weight: 'bold',
              size: 11.25
            },
            formatter: Math.round,
            rotation: 270
          },
          pointStyle: 'rect',
        }]
      },

      options_previsao: {
        devicePixelRatio: 4,
        plugins: {
          title: {
            display: false,
          },

          legend: {
            // display: false,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              font: {
                family: 'Noto Sans, sans-serif',
                size: 10,
              },
            padding: 15,
          },
            title: {

            }
          },

          tooltips: {
            enabled: false
          }
        },
        animation: {
          duration: 0,
          hover: { animationDuration: 0 },
          responsiveAnimationDuration: 0
        },
        maintainAspectRatio: false,
        layout: {
          padding: {
              top: 25,
              left: 15,
              right: 20,
              bottom: 44
          }
        },
        scales: {
          y: {
            min: 0,
            grace: '5%',
            ticks: {
              font: {
                family: 'Noto Sans, sans-serif',
                size: 10,
              },
              color: '#034876',
              padding: 20,
              maxTicksLimit: 12,
              callback: function(value, index, values) {
                return (value).toLocaleString('pt-BR');
              }
            },
            grid:{
              color: '#D9D9D9',
              drawBorder: false,
            },
            title: {
              display: true,
              padding: 0,
              fontColor: '#595959',
              text: "Energia em kWh",
              font: {
                family: 'Noto Sans, sans-serif',
                size: 10,
                weight: 'bold'
              }
            }
          },

          x: {
            ticks: {
              fonts: {
                family: 'Noto Sans, sans-serif',
                size: 10,
              },
              padding: 8,
              fontColor: '#595959',
            },
            grid: {
              color: '#D9D9D9',
              drawOnChartArea: false,
              drawTicks: false,
              drawBorder: false,
            },
            title: {
              display: true,
              padding: 0,
              fontColor: '#595959',
              text: "Mês",
              font: {
                family: 'Noto Sans, sans-serif',
                size: 10,
                weight: 'bold'
              }
            }
          }
        }
      },

      // reajuste_tarifa: 'ERRO',
      
      comparativo_5anos: {
        labels: ['Sem o sistema (5 anos)', 'Com o sistema (5 anos)'],
        datasets: [
          {
            barThickness: 80,
            data: [10, 10],
            hoverBackgroundColor: [
              '#E94C4F',
              '#45AC34',
            ],
            backgroundColor: [
              '#E94C4F',
              '#45AC34',
            ],
            datalabels: { display: false }
          },
        ],
      },

      options_comparativo_5anos: {
        devicePixelRatio: 3,
        plugins: {
          legend: {
            display: false,
          },
          
          title: {
            display: false,
          },
          
          tooltips: {
            enabled: false
          },
        },
        animation: {
          duration: 0,
          hover: { animationDuration: 0 },
          responsiveAnimationDuration: 0
        },
        maintainAspectRatio: false,
        layout: {
          padding: {
              right: 15,
              bottom: 34
          }
        },
        scales: {
          y: {
            min: 0,
            grace: '5%',
            ticks: {
              font: {
                family: 'Noto Sans, sans-serif',
                size: 11.25,
              },
              color: '#034876',
              padding: 15,
              maxTicksLimit: 12,
              callback: function(value, index, values) {
                return 'R$ ' + (value).toLocaleString('pt-BR') + ',00';
              }
            },
            grid:{
              color: '#D9D9D9',
              drawBorder: false,
            },
          },

          x: {  
            ticks: {
              font: {
                family: 'Noto Sans, sans-serif',
                size: 11.25,
              },
              color: '#595959',
              padding: 10,
            },
            grid: {
              color: '#D9D9D9',
              drawOnChartArea: false,
              drawTicks: false,
              drawBorder: false,
            }
          }
        }
      },

      geracao_25anos: {
        labels: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25
        ],
        datasets: [{
          // Geração
          barThickness: 10,
          // label: 'Bar Dataset',
          data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
      
          hoverBackgroundColor: '#4472C4',
          backgroundColor: '#4472C4',
          datalabels: { display: false }
        }]
      },
        
      options_geracao_25anos: {
        devicePixelRatio: 3,
        plugins: {
          title: {
            display: false
          },

          legend: {
            display: false,
          },

          tooltips: {
            enabled: false
          }
        },
        animation: {
          duration: 0,
          hover: { animationDuration: 0 },
          responsiveAnimationDuration: 0
        },
        maintainAspectRatio: false,
        layout: {
          padding: {
              right: 20,
              bottom: 44
          }
        },
        scales: {
          y: {
            min: 0,
            grace: '5%',
            ticks: {
              font: {
                family: 'Noto Sans, sans-serif',
                size: 11.25,
              },
              color: '#034876',
              padding: 15,
              maxTicksLimit: 12,
              callback: function(value, index, values) {
                return `${(value).toLocaleString('pt-BR')} kWh`;
              }
            },
            grid:{
              color: '#D9D9D9',
              drawBorder: false,
            },
          },
      
          x: {
            ticks: {
              font: {
                family: 'Noto Sans, sans-serif',
                size: 10,
              },
              padding: 8,
              color: '#595959',
            },
            grid: {
              color: '#D9D9D9',
              drawOnChartArea: false,
              drawTicks: false,
              drawBorder: false,
            },
            title: {
              display: true,
              padding: 0,
              color: '#595959',
              text: "ANOS",
              font: {
                family: 'Noto Sans, sans-serif',
                size: 10,
              }
            }
          }
        }
      },

      inversores_modelo: [
          "ERRO",
          "ERRO",
          "ERRO"
      ],

      inversores_imagem: [
        imagemErro,
        imagemErro,
        imagemErro
      ],

      garantias: [
        "0 ANOS",
        "00 Anos - Defeitos",
        "00 Anos - Eficiência",
        "0 Anos",
        "00 Meses"
      ],

      outras_informacoes: [
          "ERRO",
          "ERRO"
      ],

      etapas: [
          [
              "ERRO",
              "ERRO"
          ],
          [
              "ERRO",
              "ERRO"
          ],
          [
              "ERRO",
              "ERRO"
          ]
      ],

      contato: [
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO",
          "ERRO"
      ],

      assinatura_cliente: "ERRO",

      assinatura_responsavel: "ERRO"
    };
  }

  formatarDadosImpressao(propostaImpressa, dadosRoute) {
    // Dados enviados para o componente
    let objetoSaida = {};


    // ################
    // ### PAGINA 1 ###
    // ################
    
    let estrutura = propostaImpressa.aplicacao_estrutura.split(' ', 1)[0].split('-', 1)[0];

    if (estrutura === 'Laje' || estrutura === 'Solo') {
        estrutura = 'Estrutura para ' + estrutura;
    } else {
        estrutura = 'Telhado ' + estrutura;
    }

    const numero_inversores = propostaImpressa.sistema_inversor.reduce((valor_anterior, elemento) => valor_anterior + elemento.quant, 0)

    if (propostaImpressa.revisao_proposta > 1) {
      objetoSaida.identificacao_proposta = `Proposta Nº ${propostaImpressa.numero_proposta}_v${propostaImpressa.revisao_proposta}`;
    } else {
      objetoSaida.identificacao_proposta = `Proposta Nº ${propostaImpressa.numero_proposta}`;
    }
    
    objetoSaida.data_proposta = (new Date(propostaImpressa.data_apresentacao_proposta)).toLocaleDateString();

    objetoSaida.economia_capa = Math.round(((Math.floor(propostaImpressa.economia / 10))) * 10)

    let vocativo_proposta;
    if (propostaImpressa.titulo_cliente !== "N/A") {
      vocativo_proposta = (propostaImpressa.titulo_cliente + " " + propostaImpressa.nome_razao_cliente);

    } else {
      vocativo_proposta = (propostaImpressa.nome_razao_cliente);
    }


    function mascara_assinatura_cliente(documento) {
      let cleanValue = documento.replace(/\D/g, '');

      if (cleanValue.length <= 11) {
        return ' - CPF ' + cleanValue
          .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})/, '$1-$2')
          .replace(/(-\d{2})/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada

      } else {
          return ' - CNPJ ' + cleanValue
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1/$2')
            .replace(/(\d{4})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
      }
    }

    // Conclusão tramento de dados


    objetoSaida.titulo_cliente = vocativo_proposta;
    objetoSaida.economia = propostaImpressa.economia;

    objetoSaida.lista_resumo_capa = [
        propostaImpressa.sistema_sugerido.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 3}) + ' kWp',
        (propostaImpressa.sistema_modulo[0].quant + ' de ' + propostaImpressa.sistema_modulo[0].potencia + " Wp"),
        propostaImpressa.geracao_media.toLocaleString('pt-BR', {style: 'decimal', maximumFractionDigits: 0}) + ' kWh/mês',
        estrutura,
        Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(propostaImpressa.valor_final)
    ];

    objetoSaida.lcoe = JSON.parse(propostaImpressa.lcoe);

    // ##############################################################################################################################
    





    // ################
    // ### PAGINA 3 ###
    // ################
    
    objetoSaida.premissas = [
        ((propostaImpressa.consumo.reduce((acc, cur) => acc + cur)) / 12).toLocaleString('pt-BR', {style: 'decimal', maximumFractionDigits: 0}) + ' kWh/mês',
        "100 kWh mês",
        propostaImpressa.geracao_media.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' kWh/mês',
        Intl.NumberFormat('pt-BR', {style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0}).format((propostaImpressa.economia / propostaImpressa.conta_sem_sistema))
    ];

    let correcao_area = 0;
    if (estrutura === 'Laje') {
      correcao_area = dadosRoute.informacao_complementar_float.correcao_area_laje.valor;
    } else if(estrutura === 'Solo') {
      correcao_area = 4;
    } else {
      correcao_area = dadosRoute.informacao_complementar_float.correcao_area_telhado.valor;
    }


    objetoSaida.dados_sistema = [
      propostaImpressa.sistema_sugerido.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 3}) + ' kWp',
      propostaImpressa.sistema_modulo[0].quant,
      propostaImpressa.sistema_modulo[0].potencia + " Wp",
      Math.ceil(correcao_area * propostaImpressa.sistema_modulo[0].quant * propostaImpressa.sistema_modulo[0].area) + " m²",
      Math.ceil(propostaImpressa.sistema_modulo[0].peso * propostaImpressa.sistema_modulo[0].quant) + ' kg',
      numero_inversores
    ];

    const economia_ano1 = propostaImpressa.economia * 12;
    objetoSaida.estimativas = [
        (propostaImpressa.conta_sem_sistema).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}),
        (propostaImpressa.conta_com_sistema).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}),
        (propostaImpressa.economia).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}),
        (economia_ano1).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}),
        (propostaImpressa.economia_escalonado[0].valor).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}),
        (propostaImpressa.economia_escalonado[1].valor).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}),
        (propostaImpressa.economia_escalonado[4].valor).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}),
    ];

    objetoSaida.analise_investimento = [
        Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(propostaImpressa.valor_final),
        Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(propostaImpressa.valor_final_kwp),
        propostaImpressa.payback_string,
    ];

    objetoSaida.tir_escalonado = propostaImpressa.tir_escalonado.map(
      x => Intl
      .NumberFormat('pt-BR', {style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0})
      .format(x.valor / 100)
    )

    objetoSaida.vpl_escalonado = propostaImpressa.vpl_escalonado.map(
      x => ((x.valor).toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2}))
    )

    objetoSaida.tma = 0.1325;

    objetoSaida.impacto_ambiental = [
      Math.floor(dadosRoute.informacao_complementar_float.emissaoCO2_kwh.valor * (JSON.parse(propostaImpressa.geracao_25anos))[0]).toLocaleString('pt-BR') + ' kg',
      Math.floor(dadosRoute.informacao_complementar_float.arvores_kwh.valor * (JSON.parse(propostaImpressa.geracao_25anos))[0]).toLocaleString('pt-BR') + ' Árvores'
    ];

    // ##############################################################################################################################







    // ################
    // ### PAGINA 4 ###
    // ################

    
    // Gráfico Comparativo em 5 anos
    objetoSaida.comparativo_5anos = this.state.comparativo_5anos;
    objetoSaida.comparativo_5anos.datasets[0].data[0] = propostaImpressa.economia_escalonado[0].conta_antes;
    objetoSaida.comparativo_5anos.datasets[0].data[1] = propostaImpressa.economia_escalonado[0].conta_antes - propostaImpressa.economia_escalonado[0].valor;

    objetoSaida.options_comparativo_5anos = this.state.options_comparativo_5anos;
    // objetoSaida.options_comparativo_5anos.scales.y.max = objetoSaida.comparativo_5anos.datasets[0].data[0] * 1.1;


    // Gráfico Payback    
    objetoSaida.payback = this.state.payback;
    objetoSaida.payback.datasets[0].data = JSON.parse(propostaImpressa.fluxo_acumulado_25anos);

    objetoSaida.options_payback = this.state.options_payback;
    // objetoSaida.options_payback.scales.y.max = objetoSaida.payback.datasets[0].data[24] * 1.2;
    // objetoSaida.options_payback.scales.y.min = objetoSaida.payback.datasets[0].data[0] * 1.2;

    // Gráfico Estimativa de geração em 25 anos
    objetoSaida.geracao_25anos = this.state.geracao_25anos;
    objetoSaida.geracao_25anos.datasets[0].data = JSON.parse(propostaImpressa.geracao_25anos)

    objetoSaida.options_geracao_25anos = this.state.options_geracao_25anos;
    // objetoSaida.options_geracao_25anos.scales.y.max = objetoSaida.geracao_25anos.datasets[0].data[0] * 1.1;
    
    // // ##############################################################################################################################





    // // ################
    // // ### PAGINA 5 ###
    // // ################

    
    // Gráfico Previsão de Rendimento com Consumo
    objetoSaida.previsao = this.state.previsao;

    // Economia
    objetoSaida.previsao.datasets[0].data = JSON.parse(propostaImpressa.credito_ano1);

    // Consumo
    objetoSaida.previsao.datasets[1].data = propostaImpressa.consumo;

    // Geração
    objetoSaida.previsao.datasets[2].data = JSON.parse(propostaImpressa.geracao_ano1);

    objetoSaida.options_previsao = this.state.options_previsao;
    // objetoSaida.options_previsao.scales.y.max = Math.max(
    //   ...objetoSaida.previsao.datasets[0].data,
    //   ...objetoSaida.previsao.datasets[1].data,
    //   ...objetoSaida.previsao.datasets[2].data
    // ) * 1.1;
    
    
    function imagem_inversor(modelo) {
      if (modelo === "SIW100G M010 W00") {
        return SIW100G;
      }

      if (modelo === "SIW200 M085" || modelo === "SIW200 M100") {
        return goodweM;
      }
      if (modelo === "SIW400 ST075") {
        return goodweT;
      }


      if (modelo === "SIW300H M020 L1" || modelo === "SIW300H M030 L1" || modelo === "SIW300H M040 L1" || modelo === "SIW300H M050 L1" || modelo === "SIW300H M060 L1") {
        return huaweiM;
      }
      if (modelo === "SIW300 M030 W00" || modelo === "SIW300 M050 W00" || modelo === "SIW300 M060 W00" || modelo === "SIW300 M080 W00" || modelo === "SIW300 M100 W00") {
        return huaweiM;
      }
      if (modelo === "SIW500H ST012 M2" || modelo === "SIW500H ST015 M2" || modelo === "SIW500H ST020 M2") {
        return huaweiA;
      }
      if (modelo === "SIW500H SK020" || modelo === "SIW500H ST030 M3" || modelo === "SIW500H ST036 M3" || modelo === "SIW500H ST040 M3") {
        return huaweiB;
      }
      if (modelo === "SIW500H ST060" || modelo === "SIW500H ST100") {
        return huaweiC;
      }
      if (modelo === "SIW500H ST100 HV" || modelo === "SIW500G T075 W0") {
        return huaweiZ2;
      }
      if (modelo === "SIW500H ST200 HV") {
        return huaweiZ3;
      }
      if (modelo === "SIW500H ST200 H0") {
        return huaweiZ4;
      }
      if (modelo === "SIW500H ST200 H3") {
        return huaweiZ5;
      }


      if (modelo === "SIW200F M030" || modelo === "SIW200F M036" || modelo === "SIW200F M046" || modelo === "SIW200F M050" || modelo === "SIW200F M060") {
        return wegMWF;
      }
      if (modelo === "SIW200G M030 W0" || modelo === "SIW200G M050 W0" || modelo === "SIW200G M060 W0" || modelo === "SIW200G M030 W1" || modelo === "SIW200G M050 W1" || modelo === "SIW200G M060 W1") {
        return wegMWG5;
      }
      if (modelo === "SIW200G M070 W0" || modelo === "SIW200G M080 W0" || modelo === "SIW200G M090 W0" || modelo === "SIW200G M105 W0" || modelo === "SIW200G M070 W1" || modelo === "SIW200G M080 W1" || modelo === "SIW200G M090 W1" || modelo === "SIW200G M105 W1") {
        return wegMWG7;
      }
      if (modelo === "SIW400G T012 W0" || modelo === "SIW400G T015 W0" || modelo === "SIW400G T020 W0" || modelo === "SIW400G T025 W0") {
        return wegMWG25;
      }
      if (modelo === "SIW400G T075 W0" || modelo === "SIW400G T100 W0") {
        return wegMWG100;
      }
      if (modelo === "SIW400G T075 W01") {
        return wegMWG175;
      }
    }

    objetoSaida.inversores_modelo = propostaImpressa.sistema_inversor.map(x => (x.quant + 'x ' + x.modelo));
    
    objetoSaida.inversores_imagem = propostaImpressa.sistema_inversor.map(x => (imagem_inversor(x.modelo)));

    // Se mais de um inversor compõe o kit, a garantia de menor duração será exibida
    const garantia_inversor_valida = (propostaImpressa.sistema_inversor.length === 1 ? propostaImpressa.sistema_inversor[0].garantia1 : Math.min(propostaImpressa.sistema_inversor[0].garantia1, propostaImpressa.sistema_inversor[1].garantia1))

    objetoSaida.garantias = [
      garantia_inversor_valida + ' Anos',
      propostaImpressa.sistema_modulo[0].garantia1 + ' Anos - Defeitos',
      propostaImpressa.sistema_modulo[0].garantia2 + ' Anos - Eficiência',
      "10 Anos",
      "12 Meses"
    ];

    objetoSaida.outras_informacoes = [
      "7 Dias",
      "90 Dias"
    ];

    // ##############################################################################################################################


    // ################
    // ### PAGINA 6 ###
    // ################

    objetoSaida.etapas = [
      [
          Intl.NumberFormat('pt-BR', {style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0}).format((propostaImpressa.etapa1 / propostaImpressa.valor_final)),
          propostaImpressa.etapa1.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})

      ],
      [
          Intl.NumberFormat('pt-BR', {style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0}).format((propostaImpressa.etapa2 / propostaImpressa.valor_final)),
          propostaImpressa.etapa2.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})
      ],
      [
          Intl.NumberFormat('pt-BR', {style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0}).format((propostaImpressa.etapa3 / propostaImpressa.valor_final)),
          propostaImpressa.etapa3.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})
      ]
    ];

    objetoSaida.contato = [
      propostaImpressa.nome_usuario,
      propostaImpressa.titulo_usuario,
      propostaImpressa.telefone1_usuario,
      propostaImpressa.telefone2_usuario,
      propostaImpressa.email_usuario,
    ];

    objetoSaida.assinatura_responsavel = propostaImpressa.assinatura_usuario;

    objetoSaida.assinatura_cliente = [
        vocativo_proposta + mascara_assinatura_cliente(propostaImpressa.documento_cliente)
    ];

    return objetoSaida;
  }

  componentDidMount() {
    if (this.props.propostaImpressa && Object.keys(this.props.propostaImpressa).length > 0) {
      this.setState(
        this.formatarDadosImpressao(this.props.propostaImpressa, this.props.dadosRoute)
      );
    }
  }

  render() {
    return (
      <div>
        <div className="hidden">
          <div className="page" style={{ margin: "0", padding: "0" }}>
            <img src={pagina1} style={{height:'100%'}}/>

            <h1 className="identificacao_proposta">{this.state.identificacao_proposta}</h1>
            <h1 className="data_proposta">{this.state.data_proposta}</h1>

            <h2 className="cliente_economia">
              {this.state.titulo_cliente} | Economia: {
                Intl
                .NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'})
                .format(this.state.economia_capa)
              }
            </h2>

            <ul className="lista_resumo_capa">
              <li>Potência: {this.state.lista_resumo_capa[0]}</li>
              <li>Módulos: {this.state.lista_resumo_capa[1]}</li>
              <li>Geração: {this.state.lista_resumo_capa[2]}</li>
              <li>{this.state.lista_resumo_capa[3]}</li>
              <li>Investimento: {this.state.lista_resumo_capa[4]}</li>
            </ul>

            <p className="lcoe">kWh: {
                Intl
                .NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'})
                .format(this.state.lcoe)
              }
            </p>

          </div>
        </div>

        <div className="hidden">
          <div className="page" style={{ margin: "0", padding: "0" }}>
            <img src={pagina2} style={{height:'100%'}}/>
          </div>
        </div>

        <div className="hidden">
          <div className="page" style={{ margin: "0", padding: "0" }}>
            <img src={pagina3} style={{height:'100%'}}/>

            <div className="premissa1">{this.state.premissas[0]}</div>
            <div className="premissa2">{this.state.premissas[1]}</div>
            <div className="premissa3">{this.state.premissas[2]}</div>
            <div className="premissa4">{this.state.premissas[3]}</div>

            <ul className="dados_sistema">
              <li>{this.state.dados_sistema[0]}</li>
              <li>{this.state.dados_sistema[1]}</li>
              <li>{this.state.dados_sistema[2]}</li>
              <li>{this.state.dados_sistema[3]}</li>
              <li>{this.state.dados_sistema[4]}</li>
              <li>{this.state.dados_sistema[5]}</li>
            </ul>

            <ul className="estimativas_unidade">
              <li>R$</li>
              <li>R$</li>
              <li>R$</li>
              <li>R$</li>
              <li>R$</li>
              <li>R$</li>
              <li>R$</li>
            </ul>

            <ul className="estimativas_valor">
              <li> <div> {this.state.estimativas[0]} </div> </li>
              <li> <div> {this.state.estimativas[1]} </div> </li>
              <li> <div> {this.state.estimativas[2]} </div> </li>
              <li> <div> {this.state.estimativas[3]} </div> </li>
              <li> <div> {this.state.estimativas[4]} </div> </li>
              <li> <div> {this.state.estimativas[5]} </div> </li>
              <li> <div> {this.state.estimativas[6]} </div> </li>
            </ul>

            <ul className="analise_investimento_unidade">
              <li>R$</li>
              <li>R$</li>
            </ul>

            <ul className="analise_investimento_valor">
              <li> <div> {this.state.analise_investimento[0]} </div> </li>
              <li> <div> {this.state.analise_investimento[1]} </div> </li>
            </ul>

            <div className="payback">{this.state.analise_investimento[2]}</div>

            <div className="tir_escalonado">
              <div>{this.state.tir_escalonado[0]}</div>
              <div>{this.state.tir_escalonado[1]}</div>
              <div>{this.state.tir_escalonado[2]}</div>
              <div>{this.state.tir_escalonado[3]}</div>
              <div>{this.state.tir_escalonado[4]}</div>
            </div>

            <div className="vpl_escalonado_unidade">
              <div>R$</div>
              <div>R$</div>
              <div>R$</div>
              <div>R$</div>
              <div>R$</div>
            </div>

            <div className="vpl_escalonado_valor">
              <div>{this.state.vpl_escalonado[0]}</div>
              <div>{this.state.vpl_escalonado[1]}</div>
              <div>{this.state.vpl_escalonado[2]}</div>
              <div>{this.state.vpl_escalonado[3]}</div>
              <div>{this.state.vpl_escalonado[4]}</div>
            </div>

            <div className="tma">
              {Intl
                .NumberFormat('pt-BR', {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2})
                .format(this.state.tma)}
            </div>

            <ul className="impacto_ambiental">
              <li>{this.state.impacto_ambiental[0]}</li>
              <li>{this.state.impacto_ambiental[1]}</li>
            </ul>
            
          </div>
        </div>
        
        <div>
          <div className="page" style={{ margin: "0", padding: "0" }} >
            <img className="hidden" src={pagina4} style={{height:'100%'}}/>

            <div className="grafico_comparativo">
              <p className="titulo_grafico">Comparativo em 5 anos</p>
              <Bar
                data={this.state.comparativo_5anos}
                options={this.state.options_comparativo_5anos}
              />
            </div>

            <div className="grafico_payback">
              <p className="titulo_grafico">Payback</p>
              <Line
                data={this.state.payback}
                options={this.state.options_payback}
              />
            </div>

            {/* <div className="grafico_tarifa">
              <p className="titulo_grafico">Tarifa</p>
              <span>REAJUSTE ANUAL DE {this.state.reajuste_tarifa}</span>
              <Line
                data={tarifa}
                options={options_tarifa}
              />
            </div> */}

            <div className="grafico_geracao_25anos">
              <p className="titulo_grafico">Estimativa de geração em 25 anos</p>
              <Bar
                data={this.state.geracao_25anos}
                options={this.state.options_geracao_25anos}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="page" style={{ margin: "0", padding: "0" }}>
            <img className="hidden" src={pagina5} height='100%'/>

            <div className="grafico_previsao">
              <p className="titulo_grafico">Previsão de Rendimento com Consumo</p>
              <Bar
                data={this.state.previsao}
                options={this.state.options_previsao}
              />
            </div>
            
            <div className="hidden">
              <div className="inversores_modelo_wrapper">
                <div className="inversores_modelo">
                  {this.state.inversores_modelo.map((inversor, indice) => {
                    return ( <div key={indice}>{inversor}</div> )
                  })}
                </div>
              </div>

              <div className="inversores_imagem_wrapper">
                <div className="inversores_imagem">
                  {this.state.inversores_imagem.map((inversor, indice) => {
                    return (
                      <img key={indice} src={inversor} height='70px'/>
                    )
                  })}
                </div>
              </div>

              <ul className="garantias">
                <li> <div> {this.state.garantias[0]} </div> </li>
                <li> <div> <p> {this.state.garantias[1]} <br /> {this.state.garantias[2]} </p> </div> </li>
                <li> <div> {this.state.garantias[3]} </div> </li>
                <li> <div> {this.state.garantias[4]} </div> </li>
              </ul>

              <ul className="outras_informacoes">
                <li> <div> {this.state.outras_informacoes[0]} </div> </li>
                <li> <div> {this.state.outras_informacoes[1]} </div> </li>
              </ul>
            </div>

          </div>
        </div>
          
        <div className="hidden">
          <div className="page" style={{ margin: "0", padding: "0" }}>
            <img src={pagina6} height='100%'/>

            <ul className="etapas_fracao">
              <li> <div> {this.state.etapas[0][0]} </div> </li>
              <li> <div> {this.state.etapas[1][0]} </div> </li>
              <li> <div> {this.state.etapas[2][0]} </div> </li>
            </ul>

            <ul className="etapas_valor_unidade">
              <li>R$</li>
              <li>R$</li>
              <li>R$</li>
            </ul>

            <ul className="etapas_valor_valor">
              <li> <div> {this.state.etapas[0][1]} </div> </li>
              <li> <div> {this.state.etapas[1][1]} </div> </li>
              <li> <div> {this.state.etapas[2][1]} </div> </li>
            </ul>

            <ul className="contato">
              {this.state.contato[1].length > 0 ? (
                <>
                  <li> {this.state.contato[0]} </li>
                  <li className="contato_margem"> {this.state.contato[1]} </li>
                </>
              ) : (
                <>
                  <li className="contato_margem"> {this.state.contato[0]} </li>
                </>
              )}
              <li> {this.state.contato[2]} </li>
              <li> {this.state.contato[3]} </li>
              <li> {this.state.contato[4]} </li>
            </ul>

            <div className="assinatura_cliente"> {this.state.assinatura_cliente} </div>
              
            <div className="assinatura_responsavel1"> {this.state.assinatura_responsavel} </div>
            <div className="assinatura_responsavel2"> Brazil Solution Serviços LTDA - CNPJ 19.853.758-0001/09 </div>
          </div>
        </div>
      
      </div>
    );
  }
}

class ProposalPrint extends Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => <button disabled={this.props.unsaved}> <span><FiPrinter/> Imprimir </span></button>}
          content={() => this.componentRef}
        />
        <ComponentToPrint
          propostaImpressa = {this.props.propostaImpressa}
          dadosRoute = {this.props.dadosRoute}
          ref={(el) => (this.componentRef = el)}
        />
      </div>
    );
  }
}

export default ProposalPrint;

